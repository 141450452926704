<template>
  <v-container fluid :class="hasCallCenter ? 'call-center-margin' : ''">
    <v-layout column sheet>
      <h1 class="page-header">Client Payments</h1>
      <DataTable v-bind.sync="tableProps">
        <template #multiSelectActions>
          <v-menu close-on-content-click>
            <template #activator="{ on }">
              <v-btn
                :id="`client-payments-list-button-payment-actions`"
                class="btn-secondaryaction"
                v-on="on"
              >
                Payment Actions
                <v-icon>arrow_drop_down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-tile
                :id="`client-payments-list-title-collect-payment`"
                @click="showDialog('collectPayment')"
              >
                <v-list-tile-title>Collect Payment</v-list-tile-title>
              </v-list-tile>
              <v-list-tile
                :id="`client-payments-list-title-add-charge`"
                @click="showDialog('addCharge')"
              >
                <v-list-tile-title>Add Charge</v-list-tile-title>
              </v-list-tile>
              <v-list-tile
                :id="`client-payments-list-title-add-refund`"
                @click="showDialog('addRefund')"
              >
                <v-list-tile-title>Add Refund</v-list-tile-title>
              </v-list-tile>
              <v-list-tile
                :id="`client-payments-list-title-reduce-charge`"
                @click="showDialog('reduceCharge')"
              >
                <v-list-tile-title>Reduce Charge</v-list-tile-title>
              </v-list-tile>
              <v-list-tile
                :id="`client-payments-list-title-invoice`"
                @click="showDialog('invoice')"
              >
                <v-list-tile-title>Invoice</v-list-tile-title>
              </v-list-tile>
              <v-list-tile
                :id="`client-payments-list-title-change-due-date`"
                @click="showDialog('changeDueDate')"
              >
                <v-list-tile-title>Change Due Date</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-menu>
        </template>
      </DataTable>
    </v-layout>
    <PaymentSidebarWrapper
      :id="'client-payments-list-payments-sidebar'"
      v-model="dialogType"
      :rows="tableProps.selectedRows"
      @remove-item="removeItem"
      @refresh="refresh"
    />
  </v-container>
</template>

<script>
import { DateTime, Duration } from 'luxon'
import DataTable from '@/components/DataTable.vue'
import ReservationActionableColumn from '@/components/ReservationActionableColumn.vue'
import clientPaymentsActionsWrapper from '@/components/ClientPaymentsActionsWrapper'
import PaymentTableActions from '@/components/PaymentTableActions.vue'
import PaymentsListDetail from '@/components/PaymentsListDetail.vue'
import PickupRadiusFilter from '@/components/PickupRadiusFilter.vue'
import MultiMarketFilterVue from '@/components/MultiMarketFilter.vue'
import { currencyFilter } from '@/utils/currency'
import { metersToMilesString } from '@/utils/distance'
import { deepClone } from '@/utils/deepClone'
import { authComputed } from '@/state/helpers'
import PaymentSidebarWrapper from '@/components/PaymentSidebarWrapper.vue'
import {
  calculatedValues,
  datePredefined,
  noFutureDatesPredefined,
  numericRangePredefined,
  textLike,
  userPredefined,
  customerPredefined,
} from '@/utils/predefined'
import { mapActions } from 'vuex'

const reservationStatusMap = [
  { text: 'Upcoming', value: 'upcoming' },
  { text: 'Started', value: 'started' },
  { text: 'Finished', value: 'finished' },
  { text: 'Cancelled', value: 'cancelled' },
  { text: 'Hold', value: 'hold' },
]
const referralStatusMap = [
  { text: 'Not Offered', value: 'not_offered' },
  { text: 'Offered', value: 'fully_offered' },
  { text: 'Accepted', value: 'fully_accepted' },
  { text: 'Confirmed', value: 'fully_confirmed' },
  { text: 'Offered', value: 'offered' },
  { text: 'Accepted', value: 'accepted' },
  { text: 'Rejected', value: 'rejected' },
  { text: 'Confirmed', value: 'confirmed' },
]
const paymentStatusMap = [
  { text: 'Unpaid', value: 'not_paid' },
  { text: 'Partially Paid', value: 'partially_paid' },
  { text: 'Fully Paid', value: 'fully_paid' },
]
const categories = [
  {
    _t_id: 'cc4277cd',
    text: 'Payment Status',
    prop: 'paymentStatus',
    values: deepClone(paymentStatusMap),
    type: 'text',
    method: 'or',
  },
  {
    _t_id: 'cfb42fbe',
    text: 'Reservation Status',
    prop: 'reservationStatus',
    values: deepClone(reservationStatusMap),
    type: 'text',
    method: 'or',
  },
]

export default {
  components: {
    DataTable,
    PaymentSidebarWrapper,
  },
  metaInfo() {
    return {
      title: 'Client Payments',
    }
  },
  data() {
    return {
      dialogType: '',
      debounce: undefined,
      paymentOpts: [],
      filters: () => [],
      sorts: () => [],
      itemsPerPage: 10,
      page: 1,
      isAdmin: false,
      tableProps: {
        enableColumnConfig: true,
        enableSavedViews: true,
        enableStatusFilterToggle: true,
        total: 0,
        tableId: 'client_payments_table_view',
        currentPage: 1,
        perPage: 10,
        list: [],
        sort: this.sort,
        changePage: this.changePage,
        initialFilter: [],
        addFilter: this.addFilter,
        removeFilter: this.removeFilter,
        setSort: this.setSort,
        detail: PaymentsListDetail,
        detailKeyId: 'reservationId',
        isDetailed: true,
        shareFilters: this.receiveFilters,
        shareSorts: this.receiveSorts,
        loading: true,
        categories,
        isAdmin: this.isAdmin,
        rowClass: this.rowClass.bind(this),
        calculatedValues,
        columns: [],
        enableSelectableRows: true,
        enableSelectAll: true,
        selectedRows: [],
      },
    }
  },
  computed: {
    ...authComputed,
  },
  async mounted() {
    this.tableProps.isAdmin = this.currentUser?.group?.key === 'admin'
    await this.getColumns()
  },
  methods: {
    ...mapActions({ showAlert: 'app/showAlert' }),
    removeItem(item) {
      this.tableProps.selectedRows = this.tableProps.selectedRows.filter(
        (selection) => {
          return selection.item.managedId !== item.managedId
        }
      )
    },
    closeDialog() {
      this.dialogType = undefined
      this.refresh()
    },
    showDialog(type) {
      if (this.tableProps.selectedRows.length === 0) {
        return this.showAlert({
          type: 'error',
          message: 'You must select at least one reservation.',
        })
      }
      // check if selectedItems are fully paid
      const fullyPaidItems = this.tableProps.selectedRows
        .filter((item) => {
          return item.item.paymentStatus === 'fully_paid'
        })
        .map((i) => i.item.reservationId)

      if (fullyPaidItems.length && type === 'collectPayment') {
        this.showAlert({
          message: `The following reservation(s) are fully paid and may not be included in this action: ${fullyPaidItems.join(
            ', '
          )}.`,
          type: 'error',
        })
        return
      }

      // check if selectedItems are for the same customer
      const differentCustomers = this.tableProps.selectedRows.find((item) => {
        return (
          item.item.customerEmail !==
          this.tableProps.selectedRows[0].item.customerEmail
        )
      })
      if (differentCustomers) {
        this.showAlert({
          message: 'Please select a single customer to proceed.',
          type: 'error',
        })
        return
      }

      // check if selectedItems have the same checkout page
      const differentCheckouts = this.tableProps.selectedRows.find((item) => {
        return (
          item.item.checkoutPageId !==
          this.tableProps.selectedRows[0].item.checkoutPageId
        )
      })
      if (differentCheckouts) {
        this.showAlert({
          message: 'Selected reservations do not have the same checkout page.',
          type: 'error',
        })
        return
      }

      this.dialogType = type
    },
    receiveFilters(filters) {
      this.filters = filters
    },
    receiveSorts(sorts) {
      this.sorts = sorts
    },
    addFilter() {
      this.currentPage = 1
      this.refresh()
    },
    removeFilter() {
      this.currentPage = 1
      this.refresh(true)
    },
    refresh(immediate) {
      if (this.debounce) {
        clearTimeout(this.debounce)
      }
      if (immediate) {
        this.getTable()
      } else {
        this.debounce = setTimeout(this.getTable, 500)
      }
    },
    async getColumns() {
      const clientWrappedActions = await clientPaymentsActionsWrapper(
        PaymentTableActions,
        this.currentUser
      )
      const columnsOut = [
        {
          _t_id: '089d3f8b',
          prop: '/',
          component: clientWrappedActions,
          sort: false,
          filter: false,
          detail: false,
          type: 'actions',
        },
        {
          _t_id: 'd7df53d5',
          prop: 'managedId',
          component: ReservationActionableColumn,
          displayType: 'clickable-link',
          action: 'NOOP',
          text: 'ID',
          sort: true,
          filter: true,
          type: 'text',
          filterType: 'contains',
          sortProp: 'managedId',
          defaultSort: true,
          detail: false,
        },
        {
          _t_id: 'ab65abb5',
          prop: 'paymentStatus',
          text: 'Payment Status',
          computedText: (item) => this.mapCategoryText(paymentStatusMap, item),
          sort: true,
          filter: false,
          type: 'text',
          filterType: 'contains',
          detail: false,
        },
        {
          _t_id: 'adcdd544',
          prop: 'finalDropoffDate',
          text: 'Dropoff Date',
          computedText: (item) =>
            item === null ? '' : DateTime.fromISO(item).toFormat('MM/dd/yyyy'),
          sort: true,
          filter: true,
          type: 'date',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          predefined: deepClone(datePredefined),
          detail: false,
        },
        {
          _t_id: '17d71ae2',
          prop: 'balance',
          text: 'Balance',
          computedText: (item) => currencyFilter(item),
          component: ReservationActionableColumn,
          displayType: 'action-item',
          sort: true,
          filter: true,
          type: 'number',
          filterType: 'gt',
          childMethod: 'and',
          predefined: deepClone(numericRangePredefined),
          detail: false,
        },
        {
          _t_id: '17ddwqae2',
          prop: 'customerName',
          text: 'Customer',
          sort: true,
          filter: true,
          component: ReservationActionableColumn,
          type: 'text',
          action: 'CUSTOMER_DETAIL',
          detail: false,
          displayType: 'action-item',
          filterType: 'contains',
          predefined: deepClone(customerPredefined),
        },
        {
          _t_id: '1791630b',
          prop: '/',
          text: 'Referred To',
          sort: true,
          filter: true,
          detail: false,
          type: 'text',
          component: ReservationActionableColumn,
          list: 'referredTo',
          listProp: 'companyName',
          displayType: 'referred-to',
          action: 'REFERRED_TO_DETAIL',
          sortProp: 'referredTo',
          filterProp: 'referredTo',
          filterType: 'contains',
          childMethod: 'and',
          predefined: deepClone(textLike),
          permissions: ['canViewReferredTo'],
        },
        {
          _t_id: 'aee98743',
          prop: 'pickupDate',
          text: 'Pickup Date',
          computedText: (item, row) =>
            DateTime.fromISO(item, {
              zone: row.firstPickupTimeZone,
            }).toFormat('MM/dd/yyyy t ZZZZ'),
          sort: true,
          filter: true,
          type: 'date',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          predefined: deepClone(datePredefined),
          forceOneLine: true,
        },
        {
          _t_id: '7391ad9f',
          prop: 'quoteId',
          text: 'Quote ID',
          sort: true,
          filter: true,
          type: 'number',
          method: 'and',
          filterType: 'eq',
          detail: false,
        },
        {
          _t_id: 'f72a351c',
          prop: 'customerTotal',
          text: 'Reservation Total',
          computedText: this.customerTotalComputedText,
          sort: true,
          filter: true,
          type: 'number',
          component: ReservationActionableColumn,
          displayType: 'info-icon',
          sortProp: 'trip.total',
          filterProp: 'trip.total',
          filterType: 'eq',
          childMethod: 'and',
          predefined: deepClone(numericRangePredefined),
          detail: false,
        },
        {
          _t_id: '436c54d5',
          prop: 'assignedDriverPercentage',
          text: 'Driver',
          computedText: this.assignedDriverComputedText,
          sort: false,
          filter: false,
          detail: false,
          defaultHidden: true,
          action: 'ASSIGNED_DRIVER_VEHICLE_DETAIL_BROKER',
          type: 'number',
          component: ReservationActionableColumn,
          displayType: 'info-icon',
          sortProp: 'trip.total',
          filterProp: 'trip.total',
          filterType: 'eq',
          childMethod: 'and',
        },
        {
          _t_id: '8de7075a',
          prop: 'assignedVehiclePercentage',
          text: 'Vehicle',
          computedText: this.assignedVehicleComputedText,
          sort: false,
          filter: false,
          detail: false,
          defaultHidden: true,
          action: 'ASSIGNED_DRIVER_VEHICLE_DETAIL_BROKER',
          type: 'number',
          component: ReservationActionableColumn,
          displayType: 'info-icon',
          sortProp: 'trip.total',
          filterProp: 'trip.total',
          filterType: 'eq',
          childMethod: 'and',
        },
        {
          _t_id: 'd025d361',
          prop: ['bookedByFirstName', 'bookedByLastName'],
          text: 'Booked By',
          component: ReservationActionableColumn,
          sort: true,
          filter: true,
          detail: false,
          type: 'text',
          defaultHidden: true,
          action: 'BOOKED_BY_DETAIL',
          displayType: 'action-item',
          sortProp: 'bookedByLastName',
          filterType: 'contains',
          childMethod: 'and',
          predefined: userPredefined,
        },
        {
          _t_id: '73cd5a1d-45f3-476d-955e-acda6e4aaae8',
          prop: 'customer/customerAccount/name',
          text: 'Customer Account',
          sort: true,
          filter: true,
          type: 'text',
          detail: false,
          sortProp: 'trip/customer/customerAccount/name',
          filterProp: 'trip/customer/customerAccount/name',
          filterType: 'contains',
          childMethod: 'and',
          defaultHidden: true,
        },
        {
          _t_id: '37a7630b',
          prop: 'openForBid',
          text: 'Open For Bid',
          sort: true,
          filter: true,
          detail: false,
          type: 'text',
          defaultHidden: true,
          sortProp: 'trip.openForBid',
          filterProp: 'trip.openForBid',
          filterType: 'eq',
          computedText: (item) => {
            return item ? 'Open' : 'Closed'
          },
          customFilterTabDisplay: (val) => {
            return val ? 'Open' : 'Closed'
          },
          predefined: [
            {
              text: 'Open',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 1,
            },
            {
              text: 'Closed',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 0,
            },
          ],
        },
        {
          _t_id: '712a430a',
          prop: 'driverInfoEmailSent',
          text: 'Driver Info Email Sent',
          sort: true,
          filter: true,
          computedText: (item) => (item ? 'Sent' : 'Not Sent'),
          filterType: 'eq',
          defaultHidden: true,
          customFilterTabDisplay: (item) => (item ? 'Sent' : 'Not Sent'),
          predefined: [
            {
              text: 'Sent',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 1,
            },
            {
              text: 'Not Sent',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 0,
            },
          ],
        },
        {
          _t_id: 'a9818d8d',
          prop: 'dueDate',
          sortProp: 'trip.dueDate',
          filterProp: 'trip.dueDate',
          text: 'Due Date',
          computedText: (item) =>
            item === null ? '' : DateTime.fromISO(item).toFormat('MM/dd/yyyy'),
          sort: true,
          filter: true,
          type: 'date',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          predefined: deepClone(datePredefined),
          defaultHidden: true,
        },
        {
          _t_id: 'e53ec354',
          prop: 'tripTypeLabel',
          sortProp: 'trip.tripType.label',
          filterProp: 'trip.tripType.label',
          text: 'Trip Type',
          sort: true,
          filter: true,
          type: 'text',
          method: 'and',
          filterType: 'contains',
          defaultHidden: true,
        },
        {
          _t_id: '1cd7a99f',
          prop: 'referredAmount',
          text: 'Referral Total',
          computedText: this.referralTotalComputedText,
          component: ReservationActionableColumn,
          displayType: 'info-icon',
          sort: true,
          filter: true,
          defaultHidden: true,
          type: 'number',
          sortProp: 'referredAmount',
          filterProp: 'referredAmount',
          filterType: 'contains',
          predefined: deepClone(numericRangePredefined),
        },
        {
          _t_id: '63578070',
          prop: 'referralStatus',
          text: 'Referral Status',
          computedText: (item) => this.mapCategoryText(referralStatusMap, item),
          sort: true,
          filter: true,
          defaultHidden: true,
          type: 'text',
          filterType: 'contains',
        },
        {
          _t_id: '5a4b6e86',
          prop: 'reservationStatus',
          text: 'Reservation Status',
          computedText: (item) =>
            this.mapCategoryText(reservationStatusMap, item),
          sort: true,
          filter: false,
          defaultHidden: true,
          type: 'text',
          filterType: 'contains',
        },
        {
          _t_id: 'c8da5438',
          prop: 'referralPaymentStatus',
          text: 'Referral Payment Status',
          computedText: (item) => this.mapCategoryText(paymentStatusMap, item),
          sort: true,
          filter: false,
          defaultHidden: true,
          type: 'text',
          filterType: 'contains',
        },
        {
          _t_id: '32920d6e',
          prop: 'firstStopAddress',
          text: 'Pickup Location',
          sort: true,
          filter: true,
          defaultHidden: true,
          type: 'text',
          filterType: 'contains',
          childMethod: 'or',
          unset: ['6b01a5c0', 'c22dbed8'],
          predefined: [
            {
              text: 'Radius',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              controls: [
                {
                  id: '9522531a',
                  text: 'Location Radius',
                  filterType: 'radius',
                  component: PickupRadiusFilter,
                },
              ],
            },
            {
              text: 'Multiple Markets',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              controls: [
                {
                  id: '37e10ab1',
                  text: 'Multiple Markets',
                  filterType: 'contains',
                  component: MultiMarketFilterVue,
                },
              ],
            },
            {
              text: 'Search',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              controls: [
                {
                  text: 'Search',
                  filterType: 'contains',
                },
              ],
            },
          ],
        },
        {
          _t_id: '3b627c5d',
          prop: 'distance',
          filterProp: 'trip.distance',
          sortProp: 'trip.distance',
          text: 'Distance',
          computedText: (item) => metersToMilesString(item),
          sort: true,
          filter: true,
          defaultHidden: true,
          type: 'number',
          filterType: 'contains',
          childMethod: 'and',
          predefined: deepClone(numericRangePredefined),
        },
        {
          _t_id: '52a296fe',
          prop: 'drivingTime',
          filterProp: 'trip.drivingTime',
          sortProp: 'trip.drivingTime',
          text: 'Driving Time',
          computedText: (item) =>
            `${Number(
              Duration.fromObject({ seconds: item }.as('hours'))
            ).toFixed(2)} Hrs`,
          sort: true,
          filter: true,
          defaultHidden: true,
          type: 'number',
          filterType: 'contains',
          childMethod: 'and',
          predefined: deepClone(numericRangePredefined),
        },
        {
          _t_id: 'e9bbe02f',
          prop: 'firstSentDate',
          text: 'Referred Date',
          computedText: (item) =>
            item === null ? '' : DateTime.fromISO(item).toFormat('MM/dd/yyyy'),
          sort: true,
          filter: true,
          type: 'date',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          predefined: deepClone(noFutureDatesPredefined),
          defaultHidden: true,
        },
        {
          _t_id: '11cd6d05',
          prop: 'cancelledOn',
          text: 'Cancelled Date',
          computedText: (item) =>
            item ? DateTime.fromISO(item).toFormat('MM/dd/yyyy') : '',
          sort: true,
          filter: true,
          type: 'date',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          defaultHidden: true,
          predefined: deepClone(datePredefined),
        },
        {
          _t_id: '4464729b',
          prop: 'createdOn',
          text: 'Created Date',
          computedText: (item) =>
            item ? DateTime.fromISO(item).toFormat('MM/dd/yyyy') : '',
          sort: true,
          filter: true,
          type: 'date',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          defaultHidden: true,
          predefined: deepClone(datePredefined),
        },
        {
          _t_id: '6644d5e8-ed01-43cb-bae5-1b431566bcdf',
          prop: 'paymentMethodTypes',
          sortProp: 'paymentMethodTypes',
          text: 'Payment Methods',
          sort: true,
          type: 'text',
          filterType: 'contains',
          defaultHidden: true,
        },
        {
          _t_id: '6b01a5c0',
          prop: 'distanceFromMarket',
          filterType: '',
          filterPlaceholder: true,
        },
        {
          _t_id: 'c22dbed8',
          prop: 'marketId',
          filterType: 'eq',
          filterPlaceholder: true,
        },
        {
          _t_id: '1eb6452a',
          prop: 'nearestMarketId',
          filterType: 'eq',
          filterPlaceholder: true,
        },
      ]
      this.tableProps.columns = columnsOut
    },
    async getTable() {
      const sorts = this.sorts.asQueryParams()
      const filters = this.filters.asQueryParams()
      const companyId = this.currentUser?.companyId
      const params = {
        sorts,
        filters,
        companyId,
        pageSize: this.itemsPerPage,
        page: this.currentPage,
        reservations: 'company',
      }
      this.tableProps.selectAll = false
      this.selectedRows = []
      this.tableProps.loading = true
      const reservationData = await this.$store.dispatch(
        'reservations/reservationsTV',
        params
      )
      const reservations = reservationData.data
      this.tableProps.perPage = this.itemsPerPage
      this.tableProps.currentPage = this.currentPage
      this.tableProps.list = reservations.resultList
      this.tableProps.total = reservations.count
      this.tableProps.loading = false
      this.tableProps.selectedRows = []
    },
    sort(sortItem) {
      this.sorts.add(sortItem)
      this.refresh(true)
    },
    changePage(pagination) {
      this.currentPage = pagination.page
      this.itemsPerPage = pagination.rowsPerPage
      this.refresh()
    },
    mapCategoryText(map, item) {
      const match = map.find((status) => status.value === item)
      if (match) {
        return match.text
      }
      return item
    },
    customerTotalComputedText(row, column, action) {
      if (!row.customerTotal) {
        return
      }
      const { paymentStatus, customerTotal, balance, dueDate } = row
      const isPaymentOverdue = DateTime.local() > dueDate

      function createTooltipHTML() {
        const paymentStatusHeaderMap = {
          not_paid: `Unpaid${isPaymentOverdue ? ' (Overdue)' : ''}`,
          partially_paid: 'Partially Paid',
          fully_paid: 'Paid in Full',
        }

        return `<h6>${paymentStatusHeaderMap[paymentStatus || 'not_paid']}</h6>
          <p>Total Owed: ${currencyFilter(customerTotal)}${
          paymentStatus !== 'fully_paid'
            ? `<br />Balance Due: ${currencyFilter(balance)}`
            : ''
        }</p>`
      }

      function getIconValue() {
        const iconMap = {
          not_paid: 'unpaid',
          partially_paid: 'unpaid',
          fully_paid: 'full_payment',
        }
        return iconMap[paymentStatus]
      }

      function getIconColor() {
        const colorMap = {
          not_paid: 'error',
          partially_paid: 'warning',
          fully_paid: 'success',
        }

        return colorMap[paymentStatus]
      }

      const actionMap = {
        tooltip: createTooltipHTML.bind(this),
        icon: getIconValue.bind(this),
        iconcolor: getIconColor,
        total: currencyFilter.bind(this, customerTotal),
      }
      const actionFunction = actionMap[action || 'total']
      return actionFunction()
    },
    referralTotalComputedText(row, column, action) {
      const { referralPaymentStatus, referralBalance, referredAmount } = row
      const numericReferralBalance =
        typeof referralBalance === 'number' ? referralBalance : 0
      function createTooltipHTML() {
        const paymentStatusHeaderMap = {
          not_paid: 'Unpaid',
          partially_paid: 'Partially Paid',
          fully_paid: 'Paid in Full',
        }

        return `<h6>${
          paymentStatusHeaderMap[referralPaymentStatus || 'not_paid']
        }</h6>
          <p>Total Owed: ${currencyFilter(referredAmount)}${
          referralPaymentStatus !== 'fully_paid'
            ? `<br />Balance Due: ${currencyFilter(numericReferralBalance)}`
            : ''
        }</p>`
      }

      function getIconValue() {
        const iconMap = {
          not_paid: 'unpaid',
          partially_paid: 'unpaid',
          fully_paid: 'full_payment',
        }
        return iconMap[referralPaymentStatus]
      }

      function getIconColor() {
        const colorMap = {
          not_paid: 'error',
          partially_paid: 'warning',
          fully_paid: 'success',
        }

        return colorMap[referralPaymentStatus]
      }

      const actionMap = {
        tooltip: createTooltipHTML.bind(this),
        icon: getIconValue.bind(this),
        iconcolor: getIconColor,
        total: currencyFilter.bind(this, referredAmount),
      }
      const actionFunction = actionMap[action || 'total']
      return actionFunction()
    },
    assignedDriverComputedText(row, column, action) {
      const { assignedDriverPercentage } = row

      function createTooltipHTML() {
        const tooltipText =
          assignedDriverPercentage >= 100
            ? 'Fully Assigned'
            : 'Needs Assignment'
        return `<h6>${tooltipText}</h6>`
      }

      function getIconValue() {
        return 'drivers'
      }

      function getIconColor() {
        return assignedDriverPercentage >= 100 ? 'success' : 'error'
      }

      const actionMap = {
        tooltip: createTooltipHTML.bind(this),
        icon: getIconValue.bind(this),
        iconcolor: getIconColor,
        noop: () => '',
      }
      const actionFunction = actionMap[action || 'noop']
      return actionFunction()
    },
    assignedVehicleComputedText(row, column, action) {
      const { assignedVehiclePercentage } = row

      function createTooltipHTML() {
        const tooltipText =
          assignedVehiclePercentage >= 100
            ? 'Fully Assigned'
            : 'Needs Assignment'
        return `<h6>${tooltipText}</h6>`
      }

      function getIconValue() {
        return 'vehicles'
      }

      function getIconColor() {
        return assignedVehiclePercentage >= 100 ? 'success' : 'error'
      }

      const actionMap = {
        tooltip: createTooltipHTML,
        icon: getIconValue,
        iconcolor: getIconColor,
        noop: () => '',
      }
      const actionFunction = actionMap[action || 'noop']
      return actionFunction()
    },
    rowClass(rowProps) {
      const { item } = rowProps
      if (
        item.balance > 0 &&
        DateTime.local() > DateTime.fromISO(item.dueDate)
      ) {
        return ' error-background'
      }
    },
  },
}
</script>

<style lang="scss">
.cr-modal.v-card {
  border-radius: 10px !important;
}
</style>
