import Vue from 'vue'

export default async function (component) {
  const MenuDataMapper = function (row, rowProps, handleAction) {
    return [
      {
        icon: () => '',
        title: () => 'Collect Payment',
        click: () => handleAction('COLLECT_PAYMENT', rowProps),
        condition: () => row.paymentStatus !== 'fully_paid',
      },
      {
        icon: () => '',
        title: () => 'Add Charge',
        click: () => handleAction('ADD_CHARGE', rowProps),
      },
      {
        icon: () => '',
        title: () => 'Add Refund',
        click: () => handleAction('ADD_REFUND', rowProps),
        condition: () => row.paymentStatus !== 'not_paid',
      },
      {
        icon: () => '',
        title: () => 'Reduce Charge',
        click: () => handleAction('REDUCE_CHARGE', rowProps),
        condition: () => row.paymentStatus !== 'not_paid',
      },
      {
        icon: () => '',
        title: () => 'Invoice',
        click: () => handleAction('INVOICE', rowProps),
      },
      {
        icon: () => '',
        title: () => 'Change Due Date',
        click: () => handleAction('CHANGE_DUE_DATE', rowProps),
      },
    ]
  }

  return Vue.component('WrappedActions', {
    props: {
      row: {
        type: Object,
        default: () => ({}),
      },
      handleAction: {
        type: Function,
        default: () => null,
      },
      rowProps: {
        type: Object,
        default: () => ({}),
      },
    },
    render(createElement) {
      return createElement(component, {
        props: {
          row: this.row,
          handleAction: this.handleAction,
          rowProps: this.rowProps,
          menu: new MenuDataMapper(this.row, this.rowProps, this.handleAction),
        },
      })
    },
  })
}
